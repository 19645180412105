import React, { useEffect, useState } from "react"

const axios = require('axios');
import { useLocation } from '@reach/router'

import { TemplateContextProvider, useTemplateContext } from 'components/post/TemplateProvider'

import queryString from "query-string"

import "styles/embed.scss"
import {Body} from '../../.cache/fast-refresh-overlay/components/overlay'

import { restPostToData } from 'components/post/postsToCards'

import PostBody from "components/post/postBody"


const Preview = ({ data, pageContext = {}, ...props }) => {
  const templateContext = useTemplateContext()
  const { getCity } = templateContext

  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const params = Object.fromEntries(urlParams)

  const [postPreview, setPostPreview] = useState(params.p)

  const id = postPreview ? postPreview : '38961'
  const [postData, setPostData] = useState(null)

  // Take Post ID or slug and create a preview URL
  useEffect(() => {
    const query = queryString.parse(location.search)
    const url = `https://cms.vibemap.com/wp-json/wp/v2/posts/${id}?_embed`

    let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: url,
    headers: {
        'Authorization': 'Basic c3RldmVAdmliZW1hcC5jb206dEZUcXlBMHRsVU5DIThOc1hVUDFlRihW',
        'Cookie': 'PHPSESSID=29fee89daa38931353a57ef6aac27981; wpe-auth=2192f75b2093764e430d247ea0ed082543150dec934fb7a8c6874bfd986bd766'
    }
    };

    axios.request(config)
    .then(response => {
        const post = response.data
        // TODO: Map that data to match the data structure of the WP GraphQL query
        // console.log(postData)

        let postData = restPostToData(post)

        setPostData(postData)
    })
    .catch((error) => {
        console.log(error);
    });

  }, [id])

  const postCategories = postData?.categories?.nodes
  const postCategory =
    postCategories && postCategories.length > 0
      ? postData?.categories?.nodes[0]?.name
      : null

  const firstCategory = postCategories && postCategories.length > 0 && postCategories[0]
  const typeName = firstCategory && firstCategory.name
  const isVibeGuide = typeName == "Vibe Guide"

  const [city, setCity] = useState(
    postData?.postDetails?.city && !isVibeGuide
      ? postData.postDetails.city[0]
      : null
  )

  return (
      <TemplateContextProvider
        city={city}
        //onChange={getFilterContext}
        hasMap={true}
        isVibeGuide={isVibeGuide}
        tryLocation={false}
        setURLParams={false}>

        {postData
          ? <PostBody postData={postData} pageContext={pageContext} />
          : <div>Loading...</div>
        }

      </TemplateContextProvider>
  )
}

export default Preview