import renderCityText from 'components/utils/renderCityText'
import {
  getVibeInfo,
  getVibes,
  getVibesFromSlugs
} from 'vibemap-constants/dist/vibes.js'
const vibes = getVibes('all')

import { cities } from 'vibemap-constants/dist/helpers.js'
export const getCity = (key, field = 'slug') => {
  let city = cities.find(result => result[field] === key)
  if (city?.location) {
    city.centerpoint = [city.location.longitude, city.location.latitude]
    city.location.fromUser = true
  }

  return city
}

export const graphPostsToCards = (posts) => {

  const cards = posts.map(post => {

    const image = post?.node?.featuredImage?.node?.mediaItemUrl

    const card = {
      card_type: 'post',
      categories: post.node.categories.nodes.map(category => category.name),
      city: post.node.postDetails.city ? post.node.postDetails.city[0].title : null,
      content: post.node.content,
      date: post.node.date,
      image: image,
      slug: post.node.slug,
      title: post.node.title,
      type: 'Feature',
      url: post.node.uri
    }

    return card

  })

  return cards
}

export const restPostsToCards = (posts) => {

  const cards = posts.map(post => {

    const mainCategory = post._embedded["wp:term"][0][0].name
    const isVibeGuide = mainCategory === 'Vibe Guide'

    const card = {
      card_type: 'post',
      categories: [post._embedded["wp:term"][0][0].name],
      city: !isVibeGuide && post.acf.city
        ? renderCityText(post.acf.city)
        : null,
      content: post.content.rendered,
      date: post.date,
      image: post._embedded["wp:featuredmedia"] && post._embedded["wp:featuredmedia"][0]
        ? post._embedded["wp:featuredmedia"][0].source_url
        : null,
      slug: post.slug,
      title: post.title.rendered,
      type: 'Feature',
      url: post.link.replace(/^(?:\/\/|[^/]+)*/, "")
    }

    return card

  })

  return cards
}

export const restPostToData = (post) => {
  const terms = post._embedded["wp:term"]
  const postCategories = terms[0]
  const tags = terms[1]
  const places = terms[2]
  const vibes = terms[3]
  const activityCategories = terms[4]

  const postCity = post.acf.city && post.acf.city.length > 0 ? post.acf.city[0] : null
  const foundCity = getCity(postCity.post_name)
  const postDetails = {
    ...post.acf,
    city: [foundCity],
  }

  return {
    ...post,
    id: post.id,
    title: post.title.rendered,
    content: post.content.rendered,
    postDetails: postDetails,
    excerpt: post.excerpt.rendered,
    slug: post.slug,
    date: post.date,
    modified: post.modified,
    featuredImage: post?.featured_media_src_url,
    activityCategories: activityCategories,
    categories: post._embedded["wp:term"][0],
    tags: post.tags,
    vibes: vibes,
    blocks: post.blocks.map(block => {

      const { blockName, attrs } = block
      let blockData = {
        ...block,
        name: block.blockName,
      }

      if (blockName == "acf/map") {
        const customData = attrs.data
        blockData.mapBlock = {
          ...customData,
          customLocation: customData.custom_location == 0 ? false : true,
          fitBoundsToMarkers: customData.fit_bounds_to_markers == 0 ? false : true,
          heatmap: customData.heatmap == 0 ? false : true,
          height: customData.height,
          width: customData.width,
          map3D: customData.map_3D == 0 ? false : true,
          mapTheme: customData.map_theme,
          markerStyle: customData.marker_style,
          showMarkers: customData.show_markers,
          openInApp: customData.open_in_app == 0 ? false : true,
          showMarkers: customData.show_markers == 0 ? false : true,
        }
      }

      if (blockName == "acf/sing-cards") {
        const customData = attrs.data
        let { heading, subheading, posts } = customData
        blockData.singCards = {
          cardSize: customData.card_size ? customData.card_size : 'medium',
          class: customData.css_class,
          heading: customData.heading,
          subheading: customData.subheading,
          posts: [],
          showAll: customData.show_all == 0 ? false : true,
          style: customData.style,
          vibemapSuggests: customData.vibemap_suggests == 0 ? false : true,
          //events
          //places
          //showAllLink
          //vibemapSuggests
        }

        for (let i = 0; i < posts; i++) {
          const postTitle = customData[`posts_${i}_title`]
          const postType = customData[`posts_${i}_type`]
          let postImages = customData[`posts_${i}_image`]
          console.log('postImages', postImages, typeof postImages), postImages == "";
          try {
            postImages = JSON.parse(postImages)
          }
          catch (e) {
            console.log('Error parsing postImages', e);
            postImages = null
          }
          const postImage = postImages ? postImages[0] : null
          const postNote = customData[`posts_${i}_note`]
          const placeID = customData[`posts_${i}_place_id`]
          let vibes_query = customData[`posts_${i}_vibe_query`]
          let distance_query = customData[`posts_${i}_distance_query`]
          console.log('vibes_query', vibes_query, vibes);
          console.log('Got post details ', i, postTitle);
          //const { title, subcategory, image, category, index, vibes, description, note, city } = post
          const newPost = {
            title: postTitle && postTitle !== "" ? postTitle : null,
            type: postType,
            image: postImages,
            note: postNote && postNote !== "" ? postNote : null,
            id: placeID ? placeID : null,
            placeID: placeID ? placeID : null,
            distanceQuery: distance_query ? distance_query : 5,
            geoQuery: foundCity,
            vibeQuery: vibes,
          }
          blockData.singCards.posts.push(newPost)
          console.log('added post ', newPost)
        }
      }

      return blockData
    }),
  }
}


export default graphPostsToCards;
